import { Sizes } from './constants'
import { CssEntry } from './contentfulAttributes'

export interface IResumeUpload {
  __typename: 'ContentfulResumeUpload'
  contentful_id: string
  mobileWidget: 'Email Submit Form' | 'Upload Widget'
  css: CssEntry[] | null
}

export interface ICommonUploadPayload {
  referring_url: string
  utm_lead: 'upload'
  upload_source: string
}

export interface IDesktopUploadPayload {
  resume_file: File
  file_name: string
  lead_source_id: string
  lead_type_id: string
  tags: string
}

export interface PartnerApiResponse {
  status: {
    code:
      | '200'
      | '202'
      | '204'
      | '400'
      | '401'
      | '403'
      | '404'
      | '409'
      | '500'
      | '502'
      | '503'
      | string
    message: string
  }
  data: Partial<{
    accepted_tags: Record<string, string>
    checksum: string
    error: string
  }>
}

export interface IMobileUploadPayload {
  email: string
  utm_source: string
  utm_campaign: string
  utm_medium: string
  utm_content: string
  lead_source_id: string
  lead_type_id: string
  resume_file: File | null
  tags: string
}

export enum MobilePayload {
  UtmSource = 'mobile_leads_flow',
  UtmCampaign = 'confirmation_email',
  UtmMedium = 'email-only',
  UtmContent = 'cta_upload_your_resume',
  LeadSourceId = '2',
  LeadTypeId = '11',
}

export enum CommonPayload {
  UtmLead = 'upload',
}

export enum DesktopPayload {
  LeadSourceId = '11',
  LeadTypeId = '6',
}

export enum LeadsEmailPayload {
  LeadCaptureTypeId = 1,
  LeadSourceId = 12,
}

export enum WidgetTRefs {
  TrResumeUploadTRef = 'trResumeUpload',
  CommonTRef = `${TrResumeUploadTRef}.common`,
  DesktopTRef = `${TrResumeUploadTRef}.desktop`,
  MobileTRef = `${TrResumeUploadTRef}.mobile`,
  StatusModalTRef = `${TrResumeUploadTRef}.statusModal`,
}

export enum AllowedFileSizes {
  MaxSize = 10 * Sizes.OneMbBytes, // 10 MB
  MinSize = 500 * Sizes.OneByte, // 500 Bytes
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SPINNER_SIZE = 50

export enum UploadGTMEvents {
  ResumeUploadEmailSubmission = 'Resume Upload Email Submission',
  ResumeUploadError = 'Resume Upload Error',
  ResumeUploadDuplicate = 'Resume Upload Duplicate',
  ResumeFileUploadSuccess = 'Resume File Upload Success',
  ResumeUpload = 'Resume Upload',
}

export enum ApiErrors {
  DuplicateResume = 'That resume already exists in our database. Not re-added.',
}
