import React from 'react'
import {
  LiveChatLoaderProvider,
  Intercom as IntercomLoader,
} from 'react-live-chat-loader'
import useLocation from 'react-use/lib/useLocation'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import { BrandNames } from '../../types/brand'

const Intercom: React.FC = () => {
  const {
    intercomWidgetColor,
    intercomAppId,
    brandName,
    defaultResumeProduct,
  } = useSiteMetadata()
  const { pathname } = useLocation()
  const isZipJob = brandName.toLowerCase() === BrandNames.zipJob

  if (isZipJob) {
    return (
      <LiveChatLoaderProvider providerKey={intercomAppId} provider="intercom">
        <IntercomLoader color={intercomWidgetColor} />
      </LiveChatLoaderProvider>
    )
  }

  if (
    pathname?.includes(`/${defaultResumeProduct.toLowerCase()}-writing`) ||
    pathname?.includes('/match-report') ||
    pathname?.includes('/about') ||
    pathname?.includes('/contact') ||
    pathname?.includes('/landing') ||
    pathname?.includes('/faq') ||
    pathname?.includes('/purchase') ||
    pathname?.includes('/upsell')
  ) {
    return (
      <LiveChatLoaderProvider providerKey={intercomAppId} provider="intercom">
        <IntercomLoader color={intercomWidgetColor} />
      </LiveChatLoaderProvider>
    )
  }

  return null
}

export default Intercom
