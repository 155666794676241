import { GatsbyBrowser, PluginOptions as GatsbyPluginOptions } from 'gatsby'
import { generateKameleoonIframeScript } from '@talentinc/gatsby-theme-ecom/utils/kameleoon'
import { SiteMetadata } from './hooks/useSiteMetadata'

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => generateKameleoonIframeScript()

// export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     /* eslint-disable-next-line @typescript-eslint/no-var-requires */
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//
//     whyDidYouRender(React, { trackAllPureComponents: true })
//   }
// }

interface PluginOptions extends GatsbyPluginOptions {
  siteMetadata: SiteMetadata
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  { routerProps: { location } },
  { siteMetadata: { defaultResumeProduct } }: PluginOptions
) => {
  // Do not update the scroll when the user is on the pricing page and
  // the URL contains the hash. This is to make the packages' section
  // visible when the Buy Now CTA is clicked.
  if (
    // TopResume/TopCV pricing page
    (location.pathname === `/${defaultResumeProduct.toLowerCase()}-writing` ||
      // TopCV fr pricing page
      location.pathname ===
        `/redaction-${defaultResumeProduct.toLowerCase()}`) &&
    location.hash
  ) {
    return false
  }

  return true
}
